<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        灯控名称：
        <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 200px; margin-right: 20px;"></Input>
        通信ID：
        <Input type="text" v-model="filter.code" clearable placeholder="通信ID" style="width: 200px; margin-right: 20px;"></Input>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="filter.start" type="datetime"  style="width: 170px; margin-right: 10px;"></DatePicker>
         {{showLang('com.date.end')}}：
        <DatePicker v-model="filter.end" type="datetime"  style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes(7300)" type="info" :loading="loading" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        <!-- <Button type="success" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
        <Button v-if="funCodes('slhd')" type="error" :loading="loading" @click="deleteLogs" style="margin-right: 10px;">{{showLang('com.op.del.time')}}</Button>
        <Button v-if="funCodes('slhd')" type="error" :loading="loading" @click="deleteLog" style="margin-right: 10px;">{{showLang('com.op.del.select')}}</Button> -->
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" stripe round
      :export-config="{}" @checkbox-change="selectChangeEvent" :data="history" height="auto" :row-config="{isHover: true, height: rowHeight}"
      :checkbox-config="{checkField: 'checked',}" :loading="loading">
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="lampName" :title="showLang('com.tab.title')" fixed="left" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="lampCode" :title="showLang('com.export.cn.id')" fixed="left" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.date.time')" width="150" fixed="left"></vxe-column>
        <vxe-column v-if="rowHeight > 48" title="通道号" width="70" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">通道一</div>
              <div v-if="params.row.enable2" class="split-row-item">通道二</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="output1" title="亮度(%)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.output1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.output2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="color1" title="色温(%)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.color1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.color2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="voltage" title="电压(V)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.voltage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.voltage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="current" title="电流(A)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.current1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.current2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="power2" title="功率(W)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.power1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.power2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="powerOn1" title="累计亮灯(分)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.powerOn1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.powerOn2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="quality1" title="累计能耗(W)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.quality1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.quality2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="voltageLeakage1" title="漏电电压(V)" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.voltageLeakage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.voltageLeakage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="currentLeakage1" title="漏电电流(mA)" width="120" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.currentLeakage1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.currentLeakage2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="devModeName1" title="控制方式" width="100" header-align="center">
          <template #default="params">
            <div class="split-row-area">
              <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.devModeName1 }}</div>
              <div v-if="params.row.enable2" class="split-row-item">{{ params.row.devModeName2 }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="rssi" title="信号强度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="tempChip" title="芯片温度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="tempModule" title="模块温度" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="batterySun" title="电池电压" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="lightGroupName" title="所在分组" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="lightGroupCode" title="分组号" width="70" header-align="center"></vxe-column>
        <vxe-column field="gatewayName" title="关联网关" width="150" header-align="center"></vxe-column>
        <vxe-column field="gatewayCode" title="网关ID" width="100" header-align="center"></vxe-column>
      </vxe-table>
    </div>
    <div class="footer-data-area">
      <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'SearchPowerboxIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      // tempHistoryData: TempHistoryData,
      pos: {},
      tabHead: { //头部标题
        name: "名字",
        sex: "性别",
        age: "年龄",
      },
      filter: {
        name: '',
        code: '',
        start: '',
        end: '',
        index: 1,
        size: 20
      },
      form: {
        stationId: 0,
        lightId: 0,
        start: "",
        end: "",
      },
      viewCode: '',
      showTable: true,
      history: [],
      total: 0,
      rowHeight: 48,
      searchLight: null,
      lights: [],
      dataRefresh: 0,
      tabData: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['pageLayouts', 'pageSizes', 'coms6038names']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
  },
  destroyed: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    deleteLog: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      // let list = chks.map(p => p.id);
      let ids = [];
      for(let item of chks){
        if(ids.filter(p => p.id == item.id).length > 0)continue;
        if(chks.filter(p => p.id == item.id).length == 2){
          ids.push({id: item.id, time: item.fireTime});
        }
      }
      console.log("select dels rows", chks, ids)
      let that = this;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/param/DeleteHistory`, {paramId: this.form.lightId, ids: ids}).then(res => {
            if(res.code == 0){
              that.getList();
              setTimeout(() => {
                that.$Modal.info({
                  title: this.showLang('com.tips.del.success'),
                  content: this.showLang('com.tips.del.num.record',res.data),
                })
              }, 800);
            }
          });
        }
      });
    },
    deleteLogs: function(){
      let start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      let end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let that = this;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.num.record',start,end),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/param/RemoveHistory`, {paramId: this.form.lightId, start: start, end: end}).then(res => {
            if(res.code == 0){
              setTimeout(() => {
                that.$Modal.info({
                  title: this.showLang('com.tips.del.success'),
                  content: this.showLang('com.tips.del.num.record',res.data),
                })
              }, 800);
            }
          });
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    getList() {
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.$axios.post(`device/light/QueryLightLogs`, this.filter).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'history', res.data.list);
          this.total = res.data.count;
          let muti = false;
          for(let item of res.data.list){
            if(item.enable1 && item.enable2){
              muti = true;
              break;
            }
          }
          this.rowHeight = muti ? 60 : 48;
        }
      });
    },
    selectChangeEvent({rowIndex}) {
      this.tabData.forEach((el, index) => {
        if (el.lid == this.tabData[rowIndex].lid && index != rowIndex) {
          el.checked = this.tabData[rowIndex].checked
        }
      });
      this.$refs.chkTable.reloadRow(this.tabData, null, rowIndex)
    },
    spanData() {
      this.mergeData = [];
      let spanCols = [{
        name: this.showLang('com.export.cn.seq'),
        col: 0
      }, {
        name: this.showLang('com.op.select'),
        col: 1
      }, {
        name: this.showLang('com.date.time'),
        col: 2
      }, {
        name: this.showLang('com.device.pole.name'),
        col: 3
      }, {
        name: this.showLang('com.lighting.V') +'(V)',
        col: 5
      }, {
        name: this.showLang('com.stat.q')+'(KWh)',
        col: 9
      }];
      if (this.tabData.length < 2) {
        return false;
      }
      let data = "";
      let index = { row: 1, col: 1, rowspan: 1, colspan: 1 }
      for (let i = 0; i < this.tabData.length; i++) {
        if (this.tabData[i + 1] && this.tabData[i].lid == this.tabData[i + 1].lid) {
          if (index.rowspan == 1) {
            index.row = i;
          }
          index.rowspan++;
        } else {
          if (index.rowspan > 1) {
            for (let p = 0; p < spanCols.length; p++) {
              index.col = spanCols[p].col;
              data = JSON.parse(JSON.stringify(index));
              this.mergeData.push(data)
            }
          }
          index.rowspan = 1;
        }
      }
      this.dataRefresh++;
    },
    makeData(data) {
      let arr = [];
      data.forEach((el, index) => {
        let oneOb = {
          id: el.id,
          lid: index,
          name: this.searchLight.name,
          en: this.searchLight.content.en1 ? this.searchLight.content.nm1 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: this.img.val(el.content.c1, 2),
          p: el.content.p1,
          op: el.content.op1,
          q: el.content.q1,
        }
        let towOb = {
          id: el.id,
          lid: index,
          name: this.searchLight.name,
          en: this.searchLight.content.en2 ? this.searchLight.content.nm2 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c2,
          p: el.content.p2,
          op: el.content.op2,
          q: el.content.q1,
        }
        arr.push(oneOb)
        arr.push(towOb)
      });
      this.$set(this, 'tabData', arr);
      this.spanData();
    },
  }
}
</script>
<style scoped>
.active {
  background-color: #f2f6fa;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  overflow-y: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>